import objectPath from "object-path";
import merge from "deepmerge";
import layoutConfig from "@/core/config/DefaultLayoutConfig";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import {Mutation, Module, VuexModule, Action} from "vuex-module-decorators";
import LayoutConfigTypes from "@/core/config/LayoutConfigTypes";
import ApiService from "@/core/services/ApiService";
import {collection, getFirestore, orderBy, query, limit, onSnapshot, updateDoc, doc} from "firebase/firestore";
import {ElNotification} from "element-plus";
import dayjs from "dayjs";

interface Organization {
  BusinessName: string;
  BusinessType: string;
  Contactor: string;
  Cover: string;
  Email: string;
  Name: string;
  OwnerUid: string;
  PaymentBankName: string;
  PaymentBankAccount: string;
  PaymentBankAccountName: string;
  PaymentCardCVV: string;
  PaymentCardExpired: string;
  PaymentCardNumber: string;
  Phone: string;
  Slug: string;
  Status: string;
  TaxNumber: string;
  Website: string;
  Address: string;
  CreateTime: object;
  UpdateTime: object;
}

interface Notification {
  Action: string;
  Title: string;
  Description: string;
  Detail: string;
  Type: string;
  CreateTime: object;
  Checked: boolean;
  Pushed: boolean;
}

interface StoreInfo {
  config: LayoutConfigTypes;
  initial: LayoutConfigTypes;
  organizations: Organization[];
}

@Module
export default class ConfigModule extends VuexModule implements StoreInfo {
  config: LayoutConfigTypes = layoutConfig;
  initial: LayoutConfigTypes = layoutConfig;
  organizations: Organization[] = [];
  notification: Notification[] = [];
  createLoadings = false;

  /**
   * Get config from layout config
   * @returns {function(path, defaultValue): *}
   */
  get layoutConfig() {
    return (path, defaultValue) => {
      return objectPath.get(this.config, path, defaultValue);
    };
  }

  get currentOrganizations() {
    return this.organizations;
  }

  get notificationList() {
    return this.notification.map((item: any) => {
      let time
      const timeDiff = dayjs().unix() - item.CreateTime.seconds
      if (timeDiff < 3600) {
        time = `${Math.round((dayjs().unix() - item.CreateTime.seconds) / 60)}分鐘前`
      } else if (timeDiff < 86400) {
        time = `${Math.round((dayjs().unix() - item.CreateTime.seconds) / 3600)}小時前`
      } else {
        time = `${Math.round((dayjs().unix() - item.CreateTime.seconds) / 86400)}天前`
      }
      const icons = {
        success: 'gen043.svg',
        danger: 'gen040.svg',
        warning: 'gen044.svg',
        info: 'gen045.svg'
      }
      return {
        ...item,
        title: item.Title,
        description: item.Description,
        time,
        icon: "/media/icons/duotune/general/" + icons[item.Type],
        state: item.Type,
        unix: item.CreateTime.seconds
      }
    }).sort((a, b) => b.unix - a.unix)
  }

  get notificationAlert() {
    return this.notification.filter((item: any) => !item.Checked).length > 0
  }

  @Mutation
  setCreateLoadings(payload = true): void {
    this.createLoadings = payload
  }

  @Mutation
  [Mutations.SET_LAYOUT_CONFIG_PROPERTY](payload): void {
    const { property, value } = payload;
    objectPath.set(this.config, property, value);
    localStorage.setItem("config", JSON.stringify(this.config));
  }

  @Mutation
  [Mutations.RESET_LAYOUT_CONFIG]() {
    this.config = Object.assign({}, this.initial);
  }

  @Mutation
  [Mutations.OVERRIDE_LAYOUT_CONFIG](): void {
    this.config = this.initial = Object.assign(
      {},
      this.initial,
      JSON.parse(window.localStorage.getItem("config") || "{}")
    );
  }

  @Mutation
  [Mutations.OVERRIDE_PAGE_LAYOUT_CONFIG](payload): void {
    this.config = merge(this.config, payload);
  }

  @Mutation
  [Mutations.SET_ORGANIZATION](payload): void {
    this.organizations = payload
  }

  @Mutation
  [Mutations.SET_USER_NOTIFICATION](payload): void {
    this.notification.push(payload)
  }

  @Action
  [Actions.GET_ORGANIZATION](): void {
    ApiService.setHeader()
    ApiService.query('/enterprise/getOrganization').then(({ data }) => {
      let Data = []
      if (data.Data && data.Data.length > 0) {
        Data = data.Data.map(item => {
          return {
            ...item,
            title: item.Name,
            color: 'primary',
            text: item.Slug || '',
            cover: item.Cover
          }
        })
        this.context.commit(Mutations.SET_ORGANIZATION, Data)
      }
      return Data
    })
  }

  @Action
  [Actions.GET_USER_NOTIFICATION](payload): void {
    const q = query(collection(getFirestore(), `MemberData/${payload.uid}/Notify`), orderBy("CreateTime", "desc"), limit(15));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === "added") {
          this.context.commit(Mutations.SET_USER_NOTIFICATION, change.doc.data())
          if (change.doc.data().CancelLoading === true) {
            this.context.commit('setCreateLoadings', false)
          }
          if (!change.doc.data().Pushed) {
            updateDoc(doc(getFirestore(), `MemberData/${payload.uid}/Notify`, change.doc.id), {
              Pushed: true
            })
            ElNotification({
              title: change.doc.data().Title,
              message: change.doc.data().Description,
              type: change.doc.data().Type,
              duration: 10000
            })
          }
        }
      });
    });
  }
}
