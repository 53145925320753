import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { getFirestore, getDocs, query, collection } from "firebase/firestore";

import {
  getAuth,
  sendPasswordResetEmail,
  signOut,
  signInWithEmailAndPassword,
  getIdToken,
  signInWithCustomToken
} from 'firebase/auth'

export interface User {
  name: string;
  surname: string;
  email: string;
  password: string;
  api_token: string;
}

export interface UserAuthInfo {
  errors: unknown;
  user: User;
  isAuthenticated: boolean;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  errors = {};
  user = {} as User;
  claims = null;
  userMeta = null;
  permission = {};
  isAuthenticated = !!JwtService.getToken();

  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): User {
    return this.user;
  }

  get currentUserMeta(): object|null {
    return this.userMeta;
  }

  get currentUserClaims(): object|null {
    return this.claims;
  }

  get currentUserPermission(): object {
    return this.permission;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors() {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.SET_AUTH](user) {
    this.isAuthenticated = true;
    this.user = {
      ...this.user,
      ...user
    };
    this.errors = {};
    JwtService.saveToken(user.api_token);
  }

  @Mutation
  [Mutations.SET_USER](user) {
    this.user = {
      ...this.user,
      ...user
    };
  }

  @Mutation
  [Mutations.SET_USER_META](userMeta) {
    this.userMeta = userMeta;
  }

  @Mutation
  [Mutations.SET_CLAIMS](claims) {
    this.claims = claims;
  }

  @Mutation
  [Mutations.SET_USER_PERMISSION](permission) {
    this.permission = permission;
  }

  @Mutation
  [Mutations.SET_ID_TOKEN](idToken) {
    this.user.api_token = idToken;
    JwtService.saveToken(idToken);
  }

  @Mutation
  [Mutations.SET_PASSWORD](password) {
    this.user.password = password;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as User;
    this.errors = [];
    JwtService.destroyToken();
  }

  @Action
  [Actions.LOGIN](credentials) {
    return signInWithEmailAndPassword(getAuth(), credentials.email, credentials.password).then(async (result: {user: any}) => {
      this.context.commit(Mutations.SET_AUTH, {
        name: result.user.displayName,
        displayName: result.user.displayName,
        surname: '',
        email: result.user.email,
        password: '',
        api_token: result.user.accessToken,
        uid: result.user.uid,
        emailVerified: result.user.emailVerified,
        phoneNumber: result.user.phoneNumber,
        photoURL: result.user.photoURL,
      });
    }).catch((error) => {
      console.log(error)
      this.context.commit(Mutations.SET_ERROR, error)
    });
  }

  @Action({rawError: true})
  [Actions.T_LOGIN](token) {
    return signInWithCustomToken(getAuth(), token).then((result: {user: any}) => {
      this.context.commit(Mutations.SET_AUTH, {
        name: result.user.displayName,
        displayName: result.user.displayName,
        surname: '',
        email: result.user.email,
        password: '',
        api_token: result.user.accessToken,
        uid: result.user.uid,
        emailVerified: result.user.emailVerified,
        phoneNumber: result.user.phoneNumber,
        photoURL: result.user.photoURL,
      });
    }).catch((error) => {
      console.log(error)
      this.context.commit(Mutations.SET_ERROR, error)
    });
  }

  @Action
  [Actions.LOGOUT]() {
    return signOut(getAuth()).then(() => {
      this.context.commit(Mutations.PURGE_AUTH);
    })
  }

  @Action
  [Actions.REGISTER](credentials) {
    // return ApiService.post("register", credentials)
    //   .then(({ data }) => {
    //     this.context.commit(Mutations.SET_AUTH, data);
    //   })
    //   .catch(({ response }) => {
    //     this.context.commit(Mutations.SET_ERROR, response.data.errors);
    //   });
  }

  @Action
  [Actions.FORGOT_PASSWORD](payload) {
    return sendPasswordResetEmail(getAuth(), payload.email).then(() => {
      this.context.commit(Mutations.SET_ERROR, {});
    }).catch((error) => {
      this.context.commit(Mutations.SET_ERROR, error);
    });
  }

  @Action
  [Actions.GET_USER]() {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.query('/enterprise/getAccount').then(({ data }) => {
        this.context.commit(Mutations.SET_USER, {
          name:  data.Data.User.displayName,
          displayName: data.Data.User.displayName,
          email: data.Data.User.email,
          uid: data.Data.User.uid,
          emailVerified: data.Data.User.emailVerified,
          phoneNumber: data.Data.User.phoneNumber,
          photoURL: data.Data.User.photoURL,
        });
        this.context.commit(Mutations.SET_USER_META, data.Data.UserMeta);
        this.context.commit(Mutations.SET_CLAIMS, data.Data.User.customClaims || {});
        this.context.dispatch(Actions.GET_USER_PERMISSION, {
          uid: data.Data.User.uid
        })
      }).catch((error) => {
        this.context.commit(Mutations.SET_ERROR, error);
        const Code = error.Code || null
        if (Code === 'auth/id-token-revoked' || Code === 'auth/id-token-expired') {
          return this.context.dispatch(Actions.LOGOUT);
        }
      });
    }
  }

  @Action
  [Actions.GET_ID_TOKEN](payload) {
    if (payload) {
      return getIdToken(<any>getAuth().currentUser, true).then((token) => {
        this.context.commit(Mutations.SET_ID_TOKEN, token);
      }).catch((error) => {
        this.context.commit(Mutations.SET_ERROR, error);
      });
    }
    return Promise.resolve()
  }

  @Action
  [Actions.GET_USER_PERMISSION](payload) {
    // return getDoc(doc(getFirestore(), 'Permission', payload.uid)).then((doc) => {
    //   if (doc.exists()) {
    //     this.context.commit(Mutations.SET_USER_PERMISSION, doc.data());
    //   }
    // })
    // 取得所有組織內的權限
    const Data = {}
    const q = query(collection(getFirestore(), `MemberData/${payload.uid}/Permission`))
    return getDocs(q).then((querySnapShot) => {
      querySnapShot.forEach((doc) => {
        Data[doc.id] = doc.data()
      })
      this.context.commit(Mutations.SET_USER_PERMISSION, Data);
    })
  }

  @Action
  [Actions.VERIFY_AUTH](payload) {
    if (payload) {
      this.context.commit(Mutations.SET_AUTH, {
        name: payload.displayName,
        displayName: payload.displayName,
        surname: '',
        email: payload.email,
        password: '',
        api_token: payload.accessToken,
        uid: payload.uid,
        emailVerified: payload.emailVerified,
        phoneNumber: payload.phoneNumber,
        photoURL: payload.photoURL,
      });
      return true;
    }
    this.context.commit(Mutations.PURGE_AUTH);
  }
}
