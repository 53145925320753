import { createI18n } from "vue-i18n";

const messages = {
  'zh-tw': {
    dashboard: "Dashboard",
    layoutBuilder: "Layout builder",
    craft: "Crafted",
    pages: "Pages",
    profile: "Profile",
    profileOverview: "Overview",
    projects: "Projects",
    campaigns: "Campaigns",
    documents: "Documents",
    connections: "Connections",
    wizards: "Wizards",
    horizontal: "Horizontal",
    vertical: "Vertical",
    account: "Account",
    accountOverview: "Overview",
    settings: "Settings",
    authentication: "Authentication",
    basicFlow: "Basic Flow",
    signIn: "登入",
    signUp: "Sign-up",
    passwordReset: "Password Reset",
    multiStepSignUp: "Multi-steps Sign up",
    error404: "Error 404",
    error500: "Error 500",
    apps: "Apps",
    chat: "Chat",
    privateChat: "Private Chat",
    groupChat: "Group Chat",
    drawerChat: "Drawer Chat",
    widgets: "Widgets",
    widgetsLists: "Lists",
    widgetsStatistics: "Statistics",
    widgetsCharts: "Charts",
    widgetsMixed: "Mixed",
    widgetsTables: "Tables",
    widgetsFeeds: "Feeds",
    changelog: "Changelog",
    docsAndComponents: "Docs & Components",
    megaMenu: "Mega Menu",
    exampleLink: "Example link",
    modals: "Modals",
    general: "General",
    inviteFriends: "Invite Friends",
    viewUsers: "View Users",
    upgradePlan: "Upgrade Plan",
    shareAndEarn: "Share & Earn",
    forms: "Forms",
    newTarget: "New Target",
    newCard: "New Card",
    newAddress: "New Address",
    createAPIKey: "Create API Key",
    twoFactorAuth: "Two Factor Auth",
    createApp: "Create App",
    createAccount: "Create Account",
    documentation: "Documentation",
    components: "Components",
    resources: "Resources",
    activity: "Activity",
    customers: "Customers",
    gettingStarted: "Getting Started",
    customersListing: "Customers Listing",
    customerDetails: "Customers Details",
    calendarApp: "Calendar",
    subscriptions: "Subscriptions",
    getStarted: "Getting Started",
    subscriptionList: "Subscription List",
    addSubscription: "Add Subscription",
    viewSubscription: "View Subscription",
    close: '關閉',
    confirm: '確認',
    SUCCESS: '成功',
    WARN: '提示',
    INFO: '提示',
    ERROR: '錯誤',
    paymentType: {
      BANK: '銀行轉帳',
      ATM: '虛擬ATM付款',
      CREDIT: '信用卡',
      BUSINESS: '專人服務'
    },
    accountTypeOptions: {
      A: '經銷商',
      M: '用戶'
    },
    organizationStatusOptions: {
      Y: '已啟用',
      V: '審核中',
      N: '已停用',
      D: '已刪除',
    },
    contractStatusOptions: {
      Y: '生效中',
      N: '已停用',
      D: '已刪除',
      E: '已過期',
    },
    projectStatusOptions: {
      Y: '已啟用',
      O: '待啟用',
      X: '錯誤',
      N: '已停用',
      D: '已刪除',
    },
    memberStatusOptions: {
      Y: '使用中',
      P: '待處理',
      N: '禁用中',
    },
    errorCode: {
      'auth/invalid-email': '帳號格式錯誤',
      'auth/wrong-password': '密碼錯誤，請注意英文大小寫並重新輸入',
      'auth/user-not-found': '查無此帳號，請再次確認您的信箱是否填寫正確',
      'auth/popup-closed-by-user': '已取消登入',
      'auth/too-many-requests': '嘗試登入的次數過多，請稍後再試。',
      'auth/claims-too-large': '提供給 setCustomUserClaims() 的聲明載荷超過了允許的大小上限（1000 字節）。',
      'auth/email-already-exists': '此信箱已存在',
      'auth/id-token-expired': '憑證已過期，請重新登入',
      'auth/id-token-revoked': '憑證已被撤銷，請重新登入',
      'auth/insufficient-permission': '權限不足',
      'auth/internal-error': '內部錯誤，請稍後再試',
      'auth/invalid-argument': '向身份驗證方法提供的參數無效。錯誤消息應包含其他信息。',
      'auth/invalid-claims': '提供給 setCustomUserClaims() 的自定義聲明特性無效。',
      'auth/invalid-continue-uri': '網址必須是有效的網址字符串',
      'auth/invalid-creation-time': '創建時間必須是有效的世界協調時間 (UTC) 日期字符串。',
      'auth/invalid-credential': '憑證錯誤',
      'auth/invalid-disabled-field': '會員啟用狀態錯誤',
      'auth/invalid-display-name': '名稱必須是非空字串',
      'auth/invalid-dynamic-link-domain': '提供的動態鏈接網域沒有針對當前項目配置或授權。',
      'auth/invalid-email-verified': '會員狀態錯誤',
      'auth/invalid-hash-algorithm': '哈希算法必須與受支持算法列表中的某個字符串匹配。',
      'auth/invalid-hash-block-size': '哈希塊大小必須為有效數字。',
      'auth/invalid-hash-derived-key-length': '哈希派生密鑰長度必須為有效數字。',
      'auth/invalid-hash-key': '哈希密鑰必須為有效的字節緩沖區。',
      'auth/invalid-hash-memory-cost': '哈希內存開銷必須為有效數字。',
      'auth/invalid-hash-parallelization': '哈希並行數必須為有效數字。',
      'auth/invalid-hash-rounds': '哈希輪數必須為有效數字。',
      'auth/invalid-hash-salt-separator': '哈希算法加鹽分隔符字段必須是有效的字節緩沖區。',
      'auth/invalid-id-token': '憑證錯誤',
      'auth/invalid-last-sign-in-time': '上次登錄時間必須是有效的世界協調時間 (UTC) 日期字符串。',
      'auth/invalid-page-token': 'listUsers() 中提供的下一頁令牌無效。此值必須是非空字符串。',
      'auth/invalid-password': '提供的 password 用戶屬性值無效。此值必須是一個至少包含六個字符的字符串。',
      'auth/invalid-password-hash': '密碼哈希必須是有效的字節緩沖區。',
      'auth/invalid-password-salt': '密碼加密鹽必須是有效的字節緩沖區',
      'auth/invalid-phone-number': '電話格式錯誤',
      'auth/invalid-photo-url': '提供的 photoURL 用戶屬性值無效。此值必須是一個字符串網址。',
      'auth/invalid-provider-data': 'providerData 必須是有效的 UserInfo 對象數組。',
      'auth/invalid-provider-id': 'providerId 必須是有效的受支持提供方標識符字符串。',
      'auth/invalid-oauth-responsetype': '只應將一個 OAuth responseType 設為 true。',
      'auth/invalid-session-cookie-duration': '會話 Cookie 時長必須是介於 5 分鐘到 2 周之間的有效數字（以毫秒為單位）。',
      'auth/invalid-uid': '提供的 uid 必須是一個最多包含 128 個字符的非空字符串。',
      'auth/invalid-user-import': '要導入的用戶記錄無效。',
      'auth/maximum-user-count-exceeded': '已超出允許導入的用戶數上限。',
      'auth/missing-android-pkg-name': '如果需要安裝 Android 應用，則必須提供 Android 軟件包名稱。',
      'auth/missing-continue-uri': '必須在請求中提供有效的接續網址。',
      'auth/missing-hash-algorithm': '要使用密碼哈希導入用戶，必須提供哈希算法及其參數。',
      'auth/missing-ios-bundle-id': '請求缺少 iOS 軟件包 ID。',
      'auth/missing-uid': '需要為當前操作提供 uid 標識符。',
      'auth/missing-oauth-client-secret': '如需啟用 OIDC 代碼流程，必須使用 OAuth 配置客戶端密鑰。',
      'auth/operation-not-allowed': '尚未啟用該服務第三方登入或未設定代碼，請聯繫系統管理員。',
      'auth/phone-number-already-exists': '電話號碼已有用戶使用。',
      'auth/project-not-found': '沒有找到用於初始化 Admin SDK 的憑據所對應的 Firebase 項目。如需查看有關如何為您的項目生成憑據並使用該憑據對 Admin SDK 進行身份驗證的文檔，請參閱設置 Firebase 項目。',
      'auth/reserved-claims': '提供給 setCustomUserClaims() 的一個或多個自定義用戶聲明是系統預留內容。例如，OIDC 專用的聲明（例如 sub、iat、iss、exp、aud、auth_time 等）不應用作自定義聲明的鍵。',
      'auth/session-cookie-expired': '提供的 Firebase 會話 Cookie 已過期。',
      'auth/session-cookie-revoked': 'Firebase 會話 Cookie 已被撤消。',
      'auth/uid-already-exists': '提供的 uid 已被現有用戶使用。每個用戶的 uid 都必須是唯一的。',
      'auth/unauthorized-continue-uri': '接續網址的網域未列入白名單。請在 Firebase 控制台中將該網域列入白名單。',
      'auth/invalid-custom-token': '憑證錯誤或已過期，請重新登入'
    }
  },
  en: {
    dashboard: "Dashboard",
    layoutBuilder: "Layout builder",
    craft: "Crafted",
    pages: "Pages",
    profile: "Profile",
    profileOverview: "Overview",
    projects: "Projects",
    campaigns: "Campaigns",
    documents: "Documents",
    connections: "Connections",
    wizards: "Wizards",
    horizontal: "Horizontal",
    vertical: "Vertical",
    account: "Account",
    accountOverview: "Overview",
    settings: "Settings",
    authentication: "Authentication",
    basicFlow: "Basic Flow",
    signIn: "Sign-in",
    signUp: "Sign-up",
    passwordReset: "Password Reset",
    multiStepSignUp: "Multi-steps Sign up",
    error404: "Error 404",
    error500: "Error 500",
    apps: "Apps",
    chat: "Chat",
    privateChat: "Private Chat",
    groupChat: "Group Chat",
    drawerChat: "Drawer Chat",
    widgets: "Widgets",
    widgetsLists: "Lists",
    widgetsStatistics: "Statistics",
    widgetsCharts: "Charts",
    widgetsMixed: "Mixed",
    widgetsTables: "Tables",
    widgetsFeeds: "Feeds",
    changelog: "Changelog",
    docsAndComponents: "Docs & Components",
    megaMenu: "Mega Menu",
    exampleLink: "Example link",
    modals: "Modals",
    general: "General",
    inviteFriends: "Invite Friends",
    viewUsers: "View Users",
    upgradePlan: "Upgrade Plan",
    shareAndEarn: "Share & Earn",
    forms: "Forms",
    newTarget: "New Target",
    newCard: "New Card",
    newAddress: "New Address",
    createAPIKey: "Create API Key",
    twoFactorAuth: "Two Factor Auth",
    createApp: "Create App",
    createAccount: "Create Account",
    documentation: "Documentation",
    components: "Components",
    resources: "Resources",
    activity: "Activity",
    customers: "Customers",
    gettingStarted: "Getting Started",
    customersListing: "Customers Listing",
    customerDetails: "Customers Details",
    calendarApp: "Calendar",
    subscriptions: "Subscriptions",
    getStarted: "Getting Started",
    subscriptionList: "Subscription List",
    addSubscription: "Add Subscription",
    viewSubscription: "View Subscription",
    close: 'Close',
    confirm: 'OK',
    SUCCESS: 'Success',
    WARN: 'Warning',
    INFO: 'Info',
    ERROR: 'Danger',
    paymentType: {
      BANK: 'Bank Transfer',
      ATM: 'ATM',
      CREDIT: 'Credit Card',
      BUSINESS: 'By Sales'
    },
    accountTypeOptions: {
      A: 'Agent',
      M: 'User'
    },
    organizationStatusOptions: {
      Y: 'Active',
      N: 'Disabled',
      D: 'Deleted',
    },
    contractStatusOptions: {
      Y: 'Active',
      N: 'Disabled',
      D: 'Deleted',
      E: 'Expired',
    },
    projectStatusOptions: {
      Y: 'Active',
      O: 'Pending',
      N: 'Disabled',
      D: 'Deleted',
    },
    memberStatusOptions: {
      Y: 'Active',
      P: 'Pending',
      N: 'Disabled',
    },
    errorCode: {
      'auth/invalid-email': '帳號格式錯誤',
      'auth/wrong-password': '密碼錯誤，請注意英文大小寫並重新輸入',
      'auth/user-not-found': '查無此帳號，請再次確認您的信箱是否填寫正確',
      'auth/popup-closed-by-user': '已取消登入',
      'auth/too-many-requests': '嘗試登入的次數過多，請稍後再試。',
      'auth/claims-too-large': '提供給 setCustomUserClaims() 的聲明載荷超過了允許的大小上限（1000 字節）。',
      'auth/email-already-exists': '此信箱已存在',
      'auth/id-token-expired': '憑證已過期，請重新登入',
      'auth/id-token-revoked': '憑證已被撤銷，請重新登入',
      'auth/insufficient-permission': '權限不足',
      'auth/internal-error': '內部錯誤，請稍後再試',
      'auth/invalid-argument': '向身份驗證方法提供的參數無效。錯誤消息應包含其他信息。',
      'auth/invalid-claims': '提供給 setCustomUserClaims() 的自定義聲明特性無效。',
      'auth/invalid-continue-uri': '網址必須是有效的網址字符串',
      'auth/invalid-creation-time': '創建時間必須是有效的世界協調時間 (UTC) 日期字符串。',
      'auth/invalid-credential': '憑證錯誤',
      'auth/invalid-disabled-field': '會員啟用狀態錯誤',
      'auth/invalid-display-name': '名稱必須是非空字串',
      'auth/invalid-dynamic-link-domain': '提供的動態鏈接網域沒有針對當前項目配置或授權。',
      'auth/invalid-email-verified': '會員狀態錯誤',
      'auth/invalid-hash-algorithm': '哈希算法必須與受支持算法列表中的某個字符串匹配。',
      'auth/invalid-hash-block-size': '哈希塊大小必須為有效數字。',
      'auth/invalid-hash-derived-key-length': '哈希派生密鑰長度必須為有效數字。',
      'auth/invalid-hash-key': '哈希密鑰必須為有效的字節緩沖區。',
      'auth/invalid-hash-memory-cost': '哈希內存開銷必須為有效數字。',
      'auth/invalid-hash-parallelization': '哈希並行數必須為有效數字。',
      'auth/invalid-hash-rounds': '哈希輪數必須為有效數字。',
      'auth/invalid-hash-salt-separator': '哈希算法加鹽分隔符字段必須是有效的字節緩沖區。',
      'auth/invalid-id-token': '憑證錯誤',
      'auth/invalid-last-sign-in-time': '上次登錄時間必須是有效的世界協調時間 (UTC) 日期字符串。',
      'auth/invalid-page-token': 'listUsers() 中提供的下一頁令牌無效。此值必須是非空字符串。',
      'auth/invalid-password': '提供的 password 用戶屬性值無效。此值必須是一個至少包含六個字符的字符串。',
      'auth/invalid-password-hash': '密碼哈希必須是有效的字節緩沖區。',
      'auth/invalid-password-salt': '密碼加密鹽必須是有效的字節緩沖區',
      'auth/invalid-phone-number': '電話格式錯誤',
      'auth/invalid-photo-url': '提供的 photoURL 用戶屬性值無效。此值必須是一個字符串網址。',
      'auth/invalid-provider-data': 'providerData 必須是有效的 UserInfo 對象數組。',
      'auth/invalid-provider-id': 'providerId 必須是有效的受支持提供方標識符字符串。',
      'auth/invalid-oauth-responsetype': '只應將一個 OAuth responseType 設為 true。',
      'auth/invalid-session-cookie-duration': '會話 Cookie 時長必須是介於 5 分鐘到 2 周之間的有效數字（以毫秒為單位）。',
      'auth/invalid-uid': '提供的 uid 必須是一個最多包含 128 個字符的非空字符串。',
      'auth/invalid-user-import': '要導入的用戶記錄無效。',
      'auth/maximum-user-count-exceeded': '已超出允許導入的用戶數上限。',
      'auth/missing-android-pkg-name': '如果需要安裝 Android 應用，則必須提供 Android 軟件包名稱。',
      'auth/missing-continue-uri': '必須在請求中提供有效的接續網址。',
      'auth/missing-hash-algorithm': '要使用密碼哈希導入用戶，必須提供哈希算法及其參數。',
      'auth/missing-ios-bundle-id': '請求缺少 iOS 軟件包 ID。',
      'auth/missing-uid': '需要為當前操作提供 uid 標識符。',
      'auth/missing-oauth-client-secret': '如需啟用 OIDC 代碼流程，必須使用 OAuth 配置客戶端密鑰。',
      'auth/operation-not-allowed': '尚未啟用該服務第三方登入或未設定代碼，請聯繫系統管理員。',
      'auth/phone-number-already-exists': '電話號碼已有用戶使用。',
      'auth/project-not-found': '沒有找到用於初始化 Admin SDK 的憑據所對應的 Firebase 項目。如需查看有關如何為您的項目生成憑據並使用該憑據對 Admin SDK 進行身份驗證的文檔，請參閱設置 Firebase 項目。',
      'auth/reserved-claims': '提供給 setCustomUserClaims() 的一個或多個自定義用戶聲明是系統預留內容。例如，OIDC 專用的聲明（例如 sub、iat、iss、exp、aud、auth_time 等）不應用作自定義聲明的鍵。',
      'auth/session-cookie-expired': '提供的 Firebase 會話 Cookie 已過期。',
      'auth/session-cookie-revoked': 'Firebase 會話 Cookie 已被撤消。',
      'auth/uid-already-exists': '提供的 uid 已被現有用戶使用。每個用戶的 uid 都必須是唯一的。',
      'auth/unauthorized-continue-uri': '接續網址的網域未列入白名單。請在 Firebase 控制台中將該網域列入白名單。',
      'auth/invalid-custom-token': '憑證錯誤或已過期，請重新登入'
    }
  }
};

const i18n = createI18n({
  legacy: false,
  locale: "zh-tw",
  globalInjection: true,
  messages,
});

export default i18n;
