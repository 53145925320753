import { createApp } from "vue";
import App from "./App.vue";

/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import i18n from "@/core/plugins/i18n";
import dayjs from "dayjs"

//imports for app initialization
import ApiService from "@/core/services/ApiService";
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";

import "@/core/plugins/prismjs";
import "bootstrap";

//import firebase
import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { Actions } from "@/store/enums/StoreEnums";

const firebaseApp = initializeApp({
    apiKey: "AIzaSyBf-iParcLCKzoV4cvxaUQ-qY1Dc_EcSaM",
    authDomain: "mpwei-2889f.firebaseapp.com",
    databaseURL: "https://mpwei-2889f.firebaseio.com",
    projectId: "mpwei-2889f",
    storageBucket: "mpwei-2889f.appspot.com",
    messagingSenderId: "286889830978",
    appId: "1:286889830978:web:4752c4cdcd989692449bdb",
    measurementId: "G-7QS9PYMQY2"
})

getAnalytics(firebaseApp)

onAuthStateChanged(getAuth(firebaseApp), async (authUser) => {
    // const claims = authUser ? (await authUser.getIdTokenResult(true)).claims : null
    store.dispatch(Actions.VERIFY_AUTH, authUser);
})

//service workers for firebase auth
if ('serviceWorker' in window.navigator) {
    window.navigator.serviceWorker.register('/firebase-auth-sw.js', {
        scope: '/',
        type: 'module'
    }).catch((error) => {
        console.log(error)
    }).then(() => {
        console.log('Service Worker Registered')
    })
}

window.navigator.serviceWorker.ready.then((registration) => {
    // send
    registration.active?.postMessage('')
})

// receive
window.navigator.serviceWorker.addEventListener('message', (response) => {
    store.dispatch(Actions.VERIFY_AUTH, JSON.parse(response.data));
    }, {
        once: true
    }
)

const app = createApp(App);

app.use(store);
app.use(router);
app.use(ElementPlus);

ApiService.init(app);
initApexCharts(app);
initInlineSvg(app);
initVeeValidate();

app.config.globalProperties.$dayjs = dayjs
app.use(i18n);

app.mount("#app");
